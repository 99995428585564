import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { REDIRECT_URL, IS_CLIENT } from '../constants';
import { getQueryParams } from '../helpers';
import Layout from '../components/Layout';
import Login from '../components/Login';
import Requests from '../actions';
import Maintenance from './maintenance';

export const autoLoginRedirect = (autologinUrl, isPortalToken) => {
  if (autologinUrl && IS_CLIENT) {
    window.location = `${REDIRECT_URL}${autologinUrl}${isPortalToken ? '/start' : ''}`;
  }
};

const IndexPage = () => {
  const AT = IS_CLIENT && localStorage.getItem('accessToken');
  const RT = IS_CLIENT && localStorage.getItem('refreshToken');
  const [loader, setLoader] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);

  const fetchMaintenance = async () => {
    const maintenance = await Requests.getMaintenance();

    if (maintenance.data && maintenance.data.includes('mha_login')) {
      setIsMaintenance(true);
    } else {
      setIsMaintenance(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('debug-IndexPage');
    if (AT && RT) {
      (async () => {
        const { autologinUrl } = await Requests.autologin();
        autoLoginRedirect(autologinUrl);
      })();
    }

    (async () => {
      const [token, portalToken] = getQueryParams(['token', 'tempTok']);

      const redirectionToken = token || portalToken;

      if (!redirectionToken) {
        return;
      }
      setLoader(true);
      const { autologinUrl, errorMessage } = await Requests.signInFinal(redirectionToken);
      if (errorMessage) {
        toast.error(errorMessage, {
          hideProgressBar: true,
          className: 'toast',
        });
        setLoader(false);
        return;
      }

      autoLoginRedirect(autologinUrl, portalToken);
    })();
  }, []);

  useEffect(() => {
    fetchMaintenance();
  }, []);

  useEffect(() => {
    const handleFocus = () => {
      fetchMaintenance();
    };
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return (
    <>
      {isMaintenance ? (
        <Maintenance />
      ) : (
        <Layout title="academylogin">
          <Login loader={loader} />
        </Layout>
      )}
    </>
  );
};

export default IndexPage;
